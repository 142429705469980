
::v-deep .el-upload__input {
    display: none;
}

::v-deep .avatar-uploader .avatar {
    max-width: 200px;
    display: block;
    margin-top: 1rem;
}
